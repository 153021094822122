.description {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}
.description.expanded {
    max-height: 200px;
    opacity: 1;
}
.description.collapsed {
    opacity: 0;
}